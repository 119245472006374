export const defaultSelectStyles = {
	control: (base, state) => ({
		...base,
		'minHeight': 20,
		'width': '100%',
		'maxWidth': '200px',
		'border': 'none',
		// Removes weird border around container
		'&:hover': {
			// Overwrites the different states of border
			borderColor: '#d0d5d9'
		},
		'boxShadow': state.isFocused ? '#000' : '#000',
		// Styles the border color
		'borderColor': state.isFocused ? '#d0d5d9' : '#d0d5d9',
		'padding': 0,
		'fontFamily': 'Ubuntu',
		'fontSize': '12px'
	}),
	container: base => ({
		...base,
		'display': 'flex',
		'z-index': 20
	}),
	menu: base => ({
		...base
	}),
	clearIndicator: base => ({
		...base
	}),
	placeholder: base => ({
		...base,
		paddingRight: 0,
		color: '#808080',
		fontSize: '12px',
		whiteSpace: 'nowrap'
	}),
	input: base => ({
		...base,
		'height': '100%',
		'margin': 0,
		'padding': 0,
		'& input': { height: '100%' }
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: base => ({
		...base,
		height: 16,
		width: 16,
		padding: '0 2px 0 0',
		color: '#808080'
	}),
	option: (provided, state) => ({
		...provided,
		'backgroundColor': state.isSelected ? '#ABABAB' : 'transparent',
		'fontFamily': 'Ubuntu',
		'fontSize': '12px',
		'color': state.isSelected ? '#fff' : '#000',
		':hover': {
			backgroundColor: 'black',
			color: '#fff'
		}
	})
};
